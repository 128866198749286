import { Key, useState } from 'react';
import { ServiceAnomaly } from '../../../interfaces/Alert.ts';
import { t } from 'i18next';
import { OverlayTooltip } from '../../../ui/Tooltip.tsx';

interface Props {
  serviceAnomalies: ServiceAnomaly[];
}
export function ServiceHealth(props: Props) {
  const [showInconsistencies, setShowInconsistencies] = useState<boolean>(true);
  return (
    <>
      <div className={'row mt-2 mb-2'}>
        <div className={'col'}>
          <p className={'card-subtitle'}>{t('alert.title-anomaly')}</p>
        </div>
        {props.serviceAnomalies.length > 0 ? (
          <div className={'col text-end'}>
            <a onClick={() => setShowInconsistencies(!showInconsistencies)}>
              ({showInconsistencies ? t('alert.hide') : t('alert.show')})
            </a>
          </div>
        ) : (
          <></>
        )}
      </div>
      {props.serviceAnomalies.length > 0 ? (
        <>
          {showInconsistencies ? (
            <>
              {props.serviceAnomalies.map((anomaly: ServiceAnomaly, i: Key) => (
                <div
                  className={`row rounded-4 mb-2 bg-white basic-shadow py-2 m-0 p-0 border-${anomaly.level}`}
                  key={i}>
                  <div className={'col-2 my-auto text-center'}>
                    <img src={`/theme/media/${anomaly.img}`} width="30" alt="alert icon" />
                  </div>
                  <div className={'col-8 text-start p-0'}>
                    <p className={'text-basic'}>{anomaly.contentObject}</p>
                    <p className={'card-text my-auto'}>
                      {anomaly.text}
                      <OverlayTooltip text={anomaly.helpText}>
                        <img
                          src={'/theme/media/info.svg'}
                          width="15"
                          height="15"
                          className={'ms-1'}
                          alt="Info icon"
                        />
                      </OverlayTooltip>
                    </p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <p className={'card-text text-start'}>
            <img
              src={'/theme/media/success-check.svg'}
              width="30"
              alt="Success icon"
              className={'me-2'}
            />
            {t('alert.no-inconsistency')}
          </p>
        </>
      )}
    </>
  );
}
