import { Alert, ServiceAnomaly } from '../../../interfaces/Alert.ts';
import { Drawer } from 'antd';
import { Key, useEffect, useState } from 'react';
import { AlertCard } from './AlertCard.tsx';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { useAlert } from '../../../services/Alerts.ts';
import { ServiceHealth } from './ServiceHealth.tsx';
import { NotificationBadge } from '../../../ui/Badge.tsx';
import { useTerritory } from '../../../services/Territory.ts';
import { useVehiclesShiftByTerritory } from '../../../services/Vehicle.ts';
import { getServiceHealth } from './utils.ts';
import { useGeography } from '../../../services/Geography.ts';

export function AlertDrawer() {
  const params = useParams() as { territorySlug: string };
  const [showAlertDrawer, setShowAlertDrawer] = useState(false);
  const { data: alertData } = useAlert(params.territorySlug);
  const { data: territory } = useTerritory(params.territorySlug);
  const vehicles = useVehiclesShiftByTerritory(params.territorySlug);
  const { data: geography } = useGeography(params.territorySlug);
  const [serviceAnomalies, setServiceAnomalies] = useState<ServiceAnomaly[]>([]);
  const [showIssues, setShowIssues] = useState<boolean>(true);
  useEffect(() => {
    if (territory?.data && vehicles?.data) {
      setServiceAnomalies(getServiceHealth(territory?.data, vehicles?.data, geography?.data));
    }
  }, [territory?.data, vehicles?.data, alertData?.data, geography?.data]);

  return (
    <>
      <NotificationBadge
        onClick={() => setShowAlertDrawer(true)}
        count={(alertData?.data?.results?.length + serviceAnomalies.length) | 0}
      />
      <Drawer
        open={showAlertDrawer}
        placement={'right'}
        onClose={() => setShowAlertDrawer(false)}
        closeIcon={false}
        styles={{
          header: { border: 'none' },
          wrapper: {
            boxShadow: 'none',
            marginRight: '12px',
          },
          content: {
            borderRadius: '20px',
            marginTop: '72px',
            backgroundColor: 'rgb(115,119,145, 0.9)',
            height: 'calc(100vh - 60px - 50px - 24px)',
          },
          body: {
            padding: '12px',
          },
          mask: { opacity: 0 },
        }}>
        <ServiceHealth serviceAnomalies={serviceAnomalies} />
        <div className={'row mt-3 mb-2'}>
          <div className={'col'}>
            <p className={'card-subtitle'}>{t('alert.title-issues')}</p>
          </div>
          {(alertData?.data?.results?.length || 0) > 0 ? (
            <div className={'col text-end'}>
              <a onClick={() => setShowIssues(!showIssues)}>
                ({showIssues ? t('alert.hide') : t('alert.show')})
              </a>
            </div>
          ) : (
            <></>
          )}
        </div>
        {(alertData?.data?.results?.length || 0) > 0 ? (
          <>
            {showIssues ? (
              <>
                {alertData?.data?.results?.map((alert: Alert, i: Key) => (
                  <AlertCard alert={alert} key={i} />
                ))}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <p className={'card-text text-start'}>
              <img
                src={'/theme/media/success-check.svg'}
                width="30"
                alt="Success icon"
                className={'me-2'}
              />
              {t('alert.no-alert')}
            </p>
          </>
        )}
      </Drawer>
    </>
  );
}
