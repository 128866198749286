import { useQuery } from '@tanstack/react-query';
import { getRoadmap } from '../queries/getRoadmap.ts';

export const useRoadmap = (
  territorySlug: string,
  vehicleId: number,
  vehicleProviderId: string | undefined,
) => {
  return useQuery({
    queryKey: ['roadmap', territorySlug, vehicleProviderId],
    queryFn: () => getRoadmap(territorySlug, vehicleId),
    enabled: vehicleId !== undefined,
    retry: false,
  });
};
