import { useQuery } from '@tanstack/react-query';
import { getTerritories, getTerritory } from '../queries/getTerritories.ts';
import { getConfiguration } from '../queries/getConfiguration.ts';
import { Territory } from '../interfaces/Territory.ts';

export const useAllTerritories = () => {
  return useQuery({
    queryKey: ['territories'],
    queryFn: getTerritories,
    retry: false,
    select: territory =>
      territory?.data?.sort((a: Territory, b: Territory) => a.slug.localeCompare(b.slug)),
  });
};

export const useTerritory = (territorySlug: string) => {
  return useQuery({
    queryKey: ['territory', territorySlug],
    queryFn: () => getTerritory(territorySlug),
    retry: false,
  });
};

export const useTerritoryConfig = (territorySlug: string) => {
  return useQuery({
    queryKey: ['territory-config', territorySlug],
    queryFn: () => getConfiguration(territorySlug),
    retry: false,
  });
};
