import { Territory } from '../../../interfaces/Territory.ts';
import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { t } from 'i18next';
import { AUTOMATA_OFF, SHIFT_STATUS_ONGOING } from '../../../constants.ts';
import { ServiceAnomaly } from '../../../interfaces/Alert.ts';
import { NodeType } from '../../../interfaces/Node.ts';

export function getServiceHealth(
  territory: Territory,
  vehicles: IVehicle[],
  geography: NodeType[],
): ServiceAnomaly[] {
  const anomalies: ServiceAnomaly[] = [];
  const territoryProviderHealthList = Object.entries(territory.providers_health);
  for (let index = 0; index < territoryProviderHealthList.length; index++) {
    const providerHealth = territoryProviderHealthList[index];
    if (!providerHealth[1]) {
      anomalies.push({
        level: 'danger',
        img: 'red-warning.svg',
        text: `${providerHealth[0]} : ${t('service-health.connection-not-healthy')}`,
        helpText: `${t('service-health.connection-not-healthy-tips')}`,
        contentObject: territory.slug,
      });
    }
  }
  for (let index = 0; index < vehicles.length; index++) {
    const vehicle = vehicles[index];
    if (vehicle?.shift?.status === SHIFT_STATUS_ONGOING && !vehicle?.is_online) {
      anomalies.push({
        level: 'warning',
        img: 'orange-warning.svg',
        text: `${t('service-health.disconnected-with-ongoing-shift')}`,
        contentObject: vehicle.display_identifier,
        helpText: `${t('service-health.disconnected-with-ongoing-shift-tips')}`,
      });
    }
    if (vehicle?.shift?.status === SHIFT_STATUS_ONGOING && vehicle?.automata_state === AUTOMATA_OFF) {
      anomalies.push({
        level: 'warning',
        img: 'orange-warning.svg',
        text: `${t('service-health.automata-off-with-ongoing-shift')}`,
        contentObject: vehicle.display_identifier,
        helpText: `${t('service-health.automata-off-with-ongoing-shift-tips')}`,
      });
    }
  }
  for (let index = 0; index < geography.length; index++) {
    const node = geography[index];
    if (node.padam_name === null) {
      anomalies.push({
        level: 'warning',
        img: 'orange-warning.svg',
        text: `${node.name} : ${t('service-health.geography-node-is-not-mapped')}`,
        contentObject: territory.slug,
        helpText: `${t('service-health.geography-node-is-not-mapped-tips')}`,
      });
    }
  }
  return anomalies;
}
