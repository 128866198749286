import { Badge } from 'antd';
import { getLifecycleColors } from '../utils.ts';

interface Props {
  onClick: () => void;
  count: number;
}

export function NotificationBadge(props: Props) {
  return (
    <Badge count={props.count} className={'ariane'} onClick={props.onClick}>
      <img src={'/theme/media/warning.svg'} height="30" alt="notification icon" />
    </Badge>
  );
}

export function MissionLifecycleBadge(props: { lifecycle: string }) {
  return (
    <span className={'mission-lifecycle rounded-4'} style={getLifecycleColors(props.lifecycle)}>
      {props.lifecycle}
    </span>
  );
}
