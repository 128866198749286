import { useParams } from 'react-router-dom';
import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { useRoadmap } from '../../../services/Roadmap.ts';
import { useEffect, useState } from 'react';
import { getOccupiedSeatsCount } from './utils.ts';

interface Props {
  vehicle: IVehicle;
}
export function OccupiedSeats(props: Props) {
  const params = useParams() as { territorySlug: string };
  const { data: roadmapData } = useRoadmap(
    params.territorySlug,
    props.vehicle?.id,
    props.vehicle?.provider_identifier,
  );
  const [countOccupiedSeats, setCountOccupiedSeats] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (roadmapData?.data && typeof roadmapData?.data !== 'string') {
      setCountOccupiedSeats(getOccupiedSeatsCount(roadmapData.data));
    }
  }, [roadmapData]);

  return (
    <>
      {countOccupiedSeats !== undefined ? (
        <p className={'text-basic mt-1 my-auto'}>
          <img
            src={'/theme/media/occupied-seat.svg'}
            height="15"
            className="me-1"
            alt="Occupied seats"
          />{' '}
          {countOccupiedSeats}
        </p>
      ) : (
        <></>
      )}
    </>
  );
}
