import { TerritoryConfig } from '../../../interfaces/TerritoryConfig.ts';
import { createMission } from '../../../queries/getVehicles.ts';
import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { PASSENGER_STATUS_ONBOARD, SHIFT_STATUS_ONGOING } from '../../../constants.ts';
import { RoadmapMessage } from '../../../interfaces/Message.ts';

export function getBatteryType(batteryPercentage: number): string {
  let batteryType;
  if (batteryPercentage > 75) {
    batteryType = '-full';
  } else if (25 <= batteryPercentage && batteryPercentage <= 75) {
    batteryType = '-half';
  } else {
    batteryType = '';
  }
  return batteryType;
}

export function getBatteryColor(batteryPercentage: number): string {
  let batteryColor;
  if (batteryPercentage > 75) {
    batteryColor = '-success';
  } else if (25 <= batteryPercentage && batteryPercentage <= 75) {
    batteryColor = '-warning';
  } else {
    batteryColor = '-danger';
  }
  return batteryColor;
}

export function doorStatusColor(doorStatus: string): string {
  let doorStatusColor;
  if (doorStatus === 'CLOSED' || doorStatus === 'OPENED') {
    doorStatusColor = 'success';
  } else {
    doorStatusColor = 'warning';
  }
  return doorStatusColor;
}

export function formatUpdatedAt(updatedAt: string): string {
  const updated_at_sec = new Date(updatedAt);
  return new Date(updated_at_sec).toLocaleString();
}

export function getMissionCreationPayload(
  event: { target: { elements: { nodesSelect: { value: never }; activitiesSelect: { value: never } } } },
  territoryConfig: TerritoryConfig | undefined | null,
): createMission {
  let payload = {
    dispatch_destination_node_id: event.target.elements.nodesSelect.value,
    activity: 3,
  };
  if (territoryConfig?.mission.activity_enabled) {
    payload = {
      dispatch_destination_node_id: event.target.elements.nodesSelect.value,
      activity: event.target.elements.activitiesSelect.value,
    };
  }
  return payload;
}

export function getAutomataOffButtonColor(vehicle: IVehicle | undefined): string {
  let color = 'primary';
  if (vehicle?.shift?.status === SHIFT_STATUS_ONGOING && vehicle?.is_online) {
    color = 'danger';
  }
  return color;
}

export function getOccupiedSeatsCount(roadmap: RoadmapMessage): number {
  let occupiedSeatsCount = 0;
  const onboardBookingsList = [];
  for (let i = 0; i < roadmap.places.length; i++) {
    const place = roadmap.places[i];
    for (let j = 0; j < place.bookings.length; j++) {
      const booking = place.bookings[j];
      if (booking.passenger_status === PASSENGER_STATUS_ONBOARD) {
        onboardBookingsList.push(booking);
      }
    }
  }
  const uniqueBookingsList = onboardBookingsList.filter(
    (obj1, i, arr) => arr.findIndex(obj2 => obj2.id === obj1.id) === i,
  );
  for (let i = 0; i < uniqueBookingsList.length; i++) {
    const booking = uniqueBookingsList[i];
    occupiedSeatsCount += booking.requested_seats;
  }
  return occupiedSeatsCount;
}
